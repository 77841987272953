$co_updates: true;

$appt_booking: true;

// 2018 Creative refresh
$gluxre_update: true;

$text-direction: rtl;
$rdirection: left;
$ldirection: right;

//Password Tooltip For password Strengthening
$password_strengthen: true;

$akzidenz: 'Assistant', sans-serif;

$loyalty-banner-cr23update: true;
/* stylelint-disable max-line-length */
$elist-banner-desktop: '/media/export/cms/EL_IL_loyalty_Landing_Page_desktop_2732x1000.jpg' !default;
$elist-banner-mobile: '/media/export/cms/EL_IL_loyalty_Landing_Page_mobile_1280x600.jpg' !default;
$elist-banner-account-login: '/media/export/cms/EL_IL_loyalty_Login_page_1366x1616.jpg' !default;
$elist-banner-account-index-non-member: '/media/export/cms/EL_IL_loyalty_Landing_Page_desktop_2732x1000.jpg' !default;
$elist-banner-account-login-desktop-padding-top: 600px !default;
$elist-banner-account-login-mobile-padding-top: 75vw !default;
